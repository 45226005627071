import React, {Component} from "react"
import styled from 'styled-components'
import {media} from "utils/Media"
import {Row, Col, Container} from 'reactstrap'
import CardItemNew from 'components/Cards/CardItemNew'
import {graphql, StaticQuery} from 'gatsby'
import Layout from "components/Layout/Layout"
import VideoModal from "components/shared/VideoModal"
import KalturaModal from "components/shared/KalturaModal"
import HeaderCopy from "components/shared/HeaderCopy"
import CardsNew from "components/Cards/CardsNew"
import {GatsbyImage} from "gatsby-plugin-image"
import BtnPlay from 'images/icons/btn-play.svg'
import IconEye from 'images/icons/icon-eye.svg'
import IconEyeRed from 'images/icons/icon-eye-red.svg'
import IconClose from "images/icons/icon-close.svg"
import ArrowLeft from "images/icons/arrow-left.svg"
import ArrowRight from "images/icons/arrow-right.svg"

const Relative = styled.div`
  position: relative;
  width: 100%;
`
const CardWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: left;

  @media ${media.lg} {
    margin-top: -1rem;
  }
`
const VideoWrapper = styled.button`
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  position: relative;
  padding: 0;

  img {
    padding-left: 0 !important;
  }

  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.2;
    transition: opacity 200ms ease-in-out;
    z-index: 2;
  }

  .play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.5;
    transition: opacity 200ms ease-in-out;
    z-index: 3;
    width: 80px;
    height: 80px;
  }

  &:hover {
    .video-overlay {
      opacity: 0.5;
    }

    .play-btn {
      opacity: 1;
    }
  }
`

const BlockSection = styled.div`
  position: relative;
  margin-bottom: 2rem;

  @media ${media.md} {
    display: flex;
    flex-flow: row wrap;
    justify-content: left;
  }
`

const Block = styled.div`
  width: 100%;
  margin: 0 auto;

  @media ${media.md} {
    width: 310px;
  }

  @media ${media.md} {
    margin: 0 1rem 1rem;
  }

  @media ${media.lg} {
    width: 33%;
    margin: 0 0 1rem;
  }

  .collapse-content {
    height: 0;
    visibility: hidden;
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    transition: height 200ms ease;
    z-index: 1;

    &.active {
      visibility: visible;
    }

    &--inner {
      position: absolute;
      left: 0;
    }

    &--scroller {
      position: absolute;
      left: 0;
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      z-index: 2;
      margin-top: 45px;
    }
  }
`

const BlockContent = styled.div`
  border-bottom: none;
  margin-top: 2rem;

  @media ${media.lg} {
    margin-bottom: 0;
    padding-bottom: 0;
    padding: 0 1rem 1rem;
    border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
    border-right: 1px solid ${props => props.theme.colors.lightGrey};

    &:hover {
      background: linear-gradient(230.34deg, #FFFFFF 71.34%, #F9F9F9 100%);
    }
  }

  button {
    text-decoration: underline;
    -webkit-appearance: none;
    border: none;
    padding: 0;
    background: none;

    img {
      padding-left: 1rem;
    }

    .hover {
      display: none;
    }

    &:hover {
      color: ${props => props.theme.colors.red};

      .non-hover {
        display: none;
      }

      .hover {
        display: inline-block;
      }
    }
  }
`

const CloseBar = styled.div`
  width: 100%;
  height: 65px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;

  .close-icon {
    cursor: pointer;
  }

  p {
    margin-bottom: 0;
    padding-left: 1rem;
  }
`

const Arrows = styled.div`
  position: absolute;
  z-index: 3;
  right: 0;
  cursor: pointer;

  img {
    padding-left: 1rem;
  }

`

const Query = () => (
  <StaticQuery
    render={data => (
      <VideoHubPage data={data}/>
    )}
    query={graphql`
            query {
                WorkshopVideoPoster: file(relativePath: { eq: "WorkshopPage/getting-ready-to-roar.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 600, quality: 100)
                    }
                }
                allVideoHubJson {
                    edges {
                      node {
                        id
                        title
                        video
                        sourceUrl
                        copy
                        cards
                      }
                    }
                  }
                  allTeacherLibraryJson {
                    edges {
                      node {
                        id
                        tag
                        color
                        slug
                        title
                        body
                        selector
                        link
                        categories
                      }
                    }
                  }
            }
		`}
  />
)

class VideoHubPage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      vimeoModalOpen: false,
      kalturaModalOpen: false,
      selectedCategories: [],
      activeId: "",
      contentHeight: 0
    }
  }
  
  componentDidMount() {
    
    let width = document.getElementsByClassName('collapse-content--scroller')[0].offsetWidth
    
    this.setState({
      containerWidth: width
    })
    
  }
  
  selectCategory = (category) => {
    let selectedCategories = this.state.selectedCategories
    
    if (selectedCategories.includes(category)) {
      const index = selectedCategories.indexOf(category);
      selectedCategories.splice(index, 1);
    } else {
      selectedCategories.push(category)
    }
    
    this.setState({selectedCategories})
  }
  
  toggleVimeoModal = (e, src) => {
    e.preventDefault();
    
    this.setState({
      src: src,
      vimeoModalOpen: !this.state.vimeoModalOpen,
    });
  }
  
  toggleKalturaModal = (e, src) => {
    e.preventDefault();
    
    this.setState({
      src: src,
      kalturaModalOpen: !this.state.kalturaModalOpen,
    });
  }
  
  toggleCollapse = (e, id) => {
    e.preventDefault();
    const collapse = id
    let activeId = ""
    
    if (this.state.activeId !== collapse) {
      activeId = collapse;
    }
    
    this.setState({
      [collapse]: !this.state[collapse],
      activeId: activeId,
      contentHeight: 375
    })
    
  }
  
  scrollContentToLeft = (e, id) => {
    const content = document.getElementById(id);
    const content_scroll_width = content.scrollWidth;
    let scrollLeft = content.scrollLeft;
    
    scrollLeft += 220;
    
    if (scrollLeft >= content_scroll_width) {
      scrollLeft = content_scroll_width;
    }
    
    content.scrollLeft = scrollLeft;
    
  }
  
  scrollContentToRight = (e, id) => {
    const content = document.getElementById(id);
    let scrollLeft = content.scrollLeft;
    
    scrollLeft -= 220;
    
    if (scrollLeft <= 0) {
      scrollLeft = 0;
    }
    
    content.scrollLeft = scrollLeft;
  }
  
  render() {
    
    const card1 = {
      "tag": "secondary",
      "color": "yellow",
      "slug": "card1",
      "title": "Key Stage 3",
      "body": "This resource aims to help your students develop an understanding of the employability skills the team uses.",
      "selector": "pdf",
      "link": "/pdfs/Getting-Ready-to-Roar-KS3-Resource.pdf"
    }
    
    const card2 = {
      "tag": "secondary",
      "color": "yellow",
      "slug": "card2",
      "title": "Key Stage 4",
      "body": "This resource aims to help your students develop an understanding of the employability skills the team uses.",
      "selector": "pdf",
      "link": "/pdfs/Getting-Ready-to-Roar-KS4-Resource.pdf"
    }
    
    
    const videos = this.props.data.allVideoHubJson.edges.map(({node}, i) => {
      
      let showArrows;
      
      let containerWidth = this.state.containerWidth;
      let scrollWidth = node.cards.length * 216;
      
      showArrows = scrollWidth > containerWidth;
      
      return (
        <Block key={i} style={{
          marginBottom: this.state.activeId === `collapse-${i}` ? this.state.contentHeight + 25 : 0
        }}>
          <BlockContent>
            <VideoWrapper onClick={(e) => this.toggleKalturaModal(e, node.video)} aria-label="Open video" role="button"
                          tabindex="0">
              <img
                src={node.sourceUrl}
                alt={node.title}
                title={node.title}
              />
              <div className="video-overlay"/>
              <div className="play-btn">
                <img src={BtnPlay} alt="" className="img-fluid"/>
              </div>
            </VideoWrapper>
            
            <h3 className="mt-4">{node.title}</h3>
            
            <p>{node.copy}</p>
            
            <button onClick={(e) => this.toggleCollapse(e, `collapse-${i}`)}>
              View associated resources
              <img className="non-hover" src={IconEye} alt=""/>
              <img className="hover" src={IconEyeRed} alt=""/>
            </button>
          </BlockContent>
          <div className={`collapse-content ${this.state.activeId === `collapse-${i}` && 'active'}`}
               style={{height: this.state.contentHeight + 25 + "px"}}>
            <CloseBar>
              <img className="close-icon" onClick={(e) => this.toggleCollapse(e, `collapse-${i}`)} src={IconClose}
                   alt=""/>
              <p className="d-none d-sm-block"><strong>Close</strong> associated resources for {node.title}</p>
              {showArrows &&
                <Arrows>
                  <img onClick={(e) => this.scrollContentToRight(e, `collapse-scroller-${i}`)} className="arrow-left"
                       src={ArrowLeft} alt=""/>
                  <img onClick={(e) => this.scrollContentToLeft(e, `collapse-scroller-${i}`)} className="arrow-left"
                       src={ArrowRight} alt=""/>
                </Arrows>
              }
            </CloseBar>
            <div className="collapse-content--scroller" style={{height: this.state.contentHeight + "px"}}
                 id={`collapse-scroller-${i}`}>
              <Relative className="my-4">
                <div className="unselectable collapse-content--inner" style={{
                  height: this.state.contentHeight + "px",
                  width: node.cards.length * 216 + "px"
                }}>
                  <CardsNew cards={node.cards}/>
                </div>
              </Relative>
            </div>
          </div>
        </Block>
      )
    })
    
    
    return (
      <Layout slug="video-hub" title="Video hub">
        <HeaderCopy title="Video Hub"/>
        
        <Container fluid={true}>
          <Container>
            <Row className="my-4 pl-3">
              <Col xs={12}>
                <h2 className="m-0"><strong>Backstage Careers Documentary</strong></h2>
              </Col>
            </Row>
            <Row className="pl-3">
              <Col xs={12} lg={6} xl={7}>
                <VideoWrapper
                  onClick={(e) => this.toggleKalturaModal(e, "https://secure.disney.com/embed/58f9b6fe5a6ed0f4fad9b0b0?domain=www.disney.co.uk")}
                  aria-label="Open video" role="button" tabindex="0">
                  <GatsbyImage image={this.props.data.WorkshopVideoPoster.childImageSharp.gatsbyImageData}
                               alt="Getting Ready to Roar"
                               title="Getting Ready to Roar"
                  />
                  <div className="video-overlay"/>
                  <div className="play-btn">
                    <img src={BtnPlay} alt="" className="img-fluid"/>
                  </div>
                </VideoWrapper>
              </Col>
              <Col xs={12} lg={6} xl={5}>
                <CardWrapper>
                  <CardItemNew color="yellow" card={card1}/>
                  <CardItemNew color="yellow" card={card2}/>
                </CardWrapper>
              </Col>
            </Row>
            
            <Row className="pl-3">
              <Col xs={12}>
                <h3 className="mt-4">Getting Ready to Roar</h3>
                <p>A unique insight into the intense backstage preparation of a performance. Scroll down
                  for more video content.</p>
              </Col>
            </Row>
            
            <Row className="my-4">
              <Col xs={12}>
                <h2 className="pl-3 m-0"><strong>Behind-The-Scenes Videos</strong></h2>
              </Col>
            </Row>
            <BlockSection>
              {videos}
            </BlockSection>
          </Container>
        </Container>
        
        <VideoModal toggleModal={this.toggleVimeoModal} src={this.state.src} modalOpen={this.state.vimeoModalOpen}/>
        <KalturaModal toggleModal={this.toggleKalturaModal} src={this.state.src}
                      modalOpen={this.state.kalturaModalOpen}/>
      
      </Layout>
    )
  }
}

export default Query