import React, {Component} from 'react';
import styled from "styled-components";
import VideoModal from "components/shared/VideoModal"
import KalturaModal from "components/shared/KalturaModal"
import IconDownload from 'images/icons/icon-download.svg'
import IconDownloadWhite from 'images/icons/icon-download-white.svg'
import IconPlay from 'images/icons/icon-play.svg'
import IconPlayWhite from 'images/icons/icon-play-white.svg'
import IconArrow from 'images/icons/icon-arrow.svg'
import IconArrowWhite from 'images/icons/icon-arrow-white.svg'
import {media} from "utils/Media"
import cardTopBlue from "images/Resources/card-top-blue.jpg"
import cardTopGreen from "images/Resources/card-top-green.jpg"
import cardTopOrange from "images/Resources/card-top-orange.jpg"
import cardTopPurple from "images/Resources/card-top-purple.jpg"
import cardTopYellow from "images/Resources/card-top-yellow.jpg"

const Card = styled.div`
    width: 200px;
    height: 275px;
    position: relative;
    margin: 1rem 0.5rem 1rem;
    padding: calc(25px + 1rem) 1rem 1rem;
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.15);
    transition: transform 200ms ease-in-out;
    display: inline-block;
    
    &:hover {
        transform: scale(1.05);
    }
    
    .card-header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 25px;
        background: url(${cardTopOrange}) no-repeat center center;
        background-size: 100%;
    }
    
    &.orange {
        .card-header {
            background: url(${cardTopOrange}) no-repeat center center;
            background-size: 100%;
        }
    }
    
    &.blue {
        .card-header {
            background: url(${cardTopBlue}) no-repeat center center;
            background-size: 100%;
        }
    }
    
    &.green {
        .card-header {
            background: url(${cardTopGreen}) no-repeat center center;
            background-size: 100%;
        }
    }
    
    &.purple {
        .card-header {
            background: url(${cardTopPurple}) no-repeat center center;
            background-size: 100%;
        }
    }
    
    &.yellow {
        .card-header {
            background: url(${cardTopYellow}) no-repeat center center;
            background-size: 100%;
        }
    }
    
    .card-body {
        padding: 0 !important;
    }
    
    .card-footer {
        background: none !important;
        border-top: 0;
    }
    
    h4 {
        font-size: 1.125rem;
        padding-left: 0 !important;
    }
    
    p {
        font-size: 0.875rem;
    }
    
    a {
        text-decoration: underline;
        
        &:hover {
            color: ${props => props.theme.colors.red};
        }
    }
`

const DownloadButton = styled.a`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 200%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    color: ${props => props.theme.colors.white} !important;
    transition: all 150ms ease-in-out;
    cursor: pointer;
    text-decoration: none !important;
    
    &.orange {
        background-color: ${props => props.theme.colors.orange};
    }
    
    &.blue {
        background-color: ${props => props.theme.colors.blue};
    }
    
    &.green {
        background-color: ${props => props.theme.colors.green};
    }
    
    &.purple {
        background-color: ${props => props.theme.colors.purple};
    }
    
    &.yellow {
        background-color: ${props => props.theme.colors.yellow};
        color: ${props => props.theme.colors.black} !important;
    }
    
    img {
        padding-left: 1rem;
    }
    
    .hover {
        display: none;
    }
    
    &:hover {
        color: ${props => props.theme.colors.white} !important;
        background-color: ${props => props.theme.colors.black};
        
        .non-hover {
            display: none;
        }
        
        .hover {
            display: inline-block;
        }
    }
    
    @media ${media.lg} {
        width: 200px;
    }
    
    img {
        margin-left: 10px;
    }
`

class CardItemNew extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
        }
    }

    toggleModal = (e, src) => {
        e.preventDefault();

        this.setState({
            src: src,
            modalOpen: !this.state.modalOpen
        });
    }

    toggleKalturaModal = (e, src) => {
        e.preventDefault();

        this.setState({
            src: src,
            kalturaModalOpen: !this.state.kalturaModalOpen,
        });
    }

    render() {
        let card = this.props.card

        return (
            <Card key={this.props.card.id} className={`${this.props.card.slug} ${this.props.color}`}>
                <div className="card-header">
                </div>
                <div className="card-body d-flex flex-column">
                    <h4 dangerouslySetInnerHTML={{__html: this.props.card.title }}/>
                    <p>{card.body}</p>
                </div>
                <div className="card-footer">
                    {card.selector === 'pdf' &&
                    <DownloadButton download target="_blank" href={card.link} className={this.props.color}>Download PDF
                        {this.props.color === 'yellow' ?
                            <>
                                <img className="non-hover" src={IconDownload} alt="" />
                                <img className="hover" src={IconDownloadWhite} alt="" />
                            </>
                            :
                                <img src={IconDownloadWhite} alt="" />
                        }
                    </DownloadButton>
                    }
                    {card.selector === 'video' &&
                    <DownloadButton onClick={(e) => this.toggleKalturaModal(e, card.link)} aria-label="Open video" className={this.props.color}>Watch videos
                        {this.props.color === 'yellow' ?
                            <>
                                <img className="non-hover" src={IconPlay} alt="" />
                                <img className="hover" src={IconPlayWhite} alt="" />
                            </>
                            :
                                <img src={IconPlayWhite} alt="" />
                        }
                    </DownloadButton>
                    }
                    {card.selector === 'url' &&
                    <DownloadButton target={!card.link.includes("lionkingeducation.co.uk") ? '_blank' : '_self'} href={card.link} download={!card.link.includes("lionkingeducation.co.uk")} className={this.props.color}>Find out more
                        {this.props.color === 'yellow' ?
                            <>
                                <img className="non-hover" src={IconArrow} alt="" />
                                <img className="hover" src={IconArrowWhite} alt="" />
                            </>
                            :
                                <img src={IconArrowWhite} alt="" />
                        }
                    </DownloadButton>
                    }
                </div>
                <VideoModal toggleModal={this.toggleModal} src={this.state.src} modalOpen={this.state.modalOpen}/>
                <KalturaModal toggleModal={this.toggleKalturaModal} src={this.state.src} modalOpen={this.state.kalturaModalOpen}/>
            </Card>
        );
    }
}

export default CardItemNew;