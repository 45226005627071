import React, {Component} from 'react';
import CardItemNew from 'components/Cards/CardItemNew'
import styled from "styled-components";
import {graphql, StaticQuery} from "gatsby";

const Resources = styled.div`
  margin-bottom: 1rem;
  display: inline-block;

  .card-deck {
    display: flex;
    flex-flow: row wrap;
    justify-content: left;
  }

  h4 {
    font-size: 1.125rem;
    padding-left: 0.5rem;
    margin-bottom: 0;
  }
`

class CardsNew extends Component {
    listResources = (tag, color) => {
        let existingTag = []
        let totalTags = 0;


        const getResources = this.props.data.allTeacherLibraryJson.edges.map((resource, i) => {
            return this.props.cards.map((cat) => {
                if (cat === parseFloat(resource.node.id)) {
                    let currentTag = resource.node.tag

                    existingTag.push(currentTag)

                    if (currentTag === tag) {
                        totalTags++;
                        return (
                            <CardItemNew key={resource.node.id} color={color} card={resource.node}/>
                        )
                    }
                }

            })
        })

        const uniqueTags = [...new Set(existingTag)];
        if (totalTags > 0) {
            return (
                <Resources>
                    {tag === 'primary' && uniqueTags.includes('primary') &&
                        <h4><strong>Primary resources</strong></h4>
                    }
                    {tag === 'secondary' && uniqueTags.includes('secondary') &&
                        <h4><strong>Secondary resources</strong></h4>
                    }
                    {tag === 'behind' && uniqueTags.includes('behind') &&
                        <h4><strong>Behind-The-Scenes</strong></h4>
                    }
                    {tag === 'backstage' && uniqueTags.includes('backstage') &&
                        <h4><strong>Backstage</strong></h4>
                    }
                    {tag === 'citizenship' && uniqueTags.includes('citizenship') &&
                        <h4><strong>Citizenship</strong></h4>
                    }
                    {tag === 'background' && uniqueTags.includes('background') &&
                        <h4><strong>Background Reading</strong></h4>
                    }
                    {tag === 'video-questionnaires' && uniqueTags.includes('video-questionnaires') &&
                        <h4><strong>Video Hub Questionnaires</strong></h4>
                    }
                    <div className="card-deck-wrapper">
                        <div className="card-deck">
                            {getResources}
                        </div>
                    </div>
                </Resources>
            )
        }
    }

    render() {
        return (
            <div style={{
                display: "flex"
            }}>
                {this.listResources('primary', 'orange')}
                {this.listResources('secondary', 'blue')}
                {this.listResources('behind', 'yellow')}
                {this.listResources('backstage', 'yellow')}
                {this.listResources('citizenship', 'purple')}
                {this.listResources('background', 'green')}
                {this.listResources('video-questionnaires', 'yellow')}
            </div>
        );
    }
}

const Query = (props) => (
    <StaticQuery
        render={data => (
            <CardsNew {...props} data={data}/>
        )}
        query={graphql`
            query {
              allTeacherLibraryJson {
                edges {
                  node {
                    id
                    tag
                    slug
                    title
                    body
                    selector
                    link
                    categories
                  }
                }
              }
            }
		`}
    />
)

export default Query