import React, {Component} from 'react';
import {Modal, ModalBody} from "reactstrap"
import styled from "styled-components"
import ButtonClose from 'components/shared/ButtonClose'

const VModal = styled(Modal)`
    max-width: 1400px;
    padding: 0;
    border: 0;

    .modal-content {
        border: 0;
        position: relative;
    }

    .modal-body {
        padding: 0;
        border: 0;
    }
    
    .video-wrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 aspect ratio */
        padding-top: 0; // height of controls
        height: 0;
    
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
    }
`

class VideoModal extends Component {
    render() {
        return (
            <VModal isOpen={this.props.modalOpen} toggle={this.props.toggleModal} centered={true}>
                <ButtonClose onClick={this.props.toggleModal}>
                    <span className="sr-only">Close</span>
                </ButtonClose>
                <ModalBody>
                    <div className="video-wrapper">
                        <iframe src={`https://player.vimeo.com/video/${this.props.src}`} width="640" height="360" frameBorder="0"
                                allow="autoplay; fullscreen" allowFullScreen/>
                    </div>
                </ModalBody>
            </VModal>
        );
    }
}

export default VideoModal;